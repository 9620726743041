<div class="p-grid workStyle">
    <div class="p-col-12 p-md-8">
        <div class="p-grid">
            <div class="p-col-12 p-md-6 header">
                Tree {{type}} [<span class="link" (click)="navigate()">{{workOrder.quoteNumber}}</span>] <span style="font-weight: normal;">{{workOrder.address.street}}, {{workOrder.address.city}}, {{workOrder.address.state.abbreviation}} {{workOrder.address.zip}}</span>
            </div>
            <div class="p-col-2 hide-sm" style="text-align: right; font-weight: bold;">
                <span *ngIf="canSeeHiddenPrice || !workOrder.hidePrice">{{workOrder.priceSummary | currency}}</span>
            </div>
            <div class="p-col-4 hide-sm"></div>
        </div>
        <div class="p-grid" *ngFor="let task of tasks">
            <div class="p-col-12 p-md-5 small-bottom-padding" style="font-weight: bold;">
                {{task.name}} [{{getTreeList(task)}}]<span class="hide-sm">, {{task.location}}</span>
            </div>
            <div class="p-col-3 hide-sm" style="text-align: right;">
                <span *ngIf="(canSeeHiddenPrice || !workOrder.hidePrice) && task.fixedPrice">
                    {{task.price | currency}}
                  </span>
                  <div class="fine-print" style="text-align: right; width: 100%">
                    <span *ngIf="task.minPrice || task.maxPrice">Please note there will be a </span>
                    <span *ngIf="task.minPrice">minimum charge of {{task.minPrice | currency}}</span>
                    <span *ngIf="task.minPrice && task.maxPrice"> and a </span>
                    <span *ngIf="task.maxPrice">maximum charge of {{task.maxPrice | currency}}</span>
                  </div>
            </div>
            <div class="hide-md p-col-12" style="padding: 0px !important;">
            </div>
            <div *ngIf="current && showDueDates" class="p-col-12 p-md-4 small-top-padding">
                {{showScheduled(task) ? 'Scheduled: ' + (task.scheduleDateFrom | date: 'shortDate') : 'Estimated Date: ' + (task.dueDateStart | date: 'shortDate') + ' - ' + (task.dueDateEnd | date: 'shortDate') }}
            </div>
            <div *ngIf="!current" class="p-col-12 p-md-4 small-top-padding">
                <span *ngIf="task.completionStatus == CompletionStatus.Completed">Completed: {{task.completedDate ? (task.completedDate | date: 'shortDate') : 'N/A'}}</span>
                <span *ngIf="task.completionStatus == CompletionStatus.Unable_to_be_Completed">Unable to be Completed</span>
                <span *ngIf="task.paymentStatus">&nbsp;|&nbsp;{{task.paymentStatus}}{{task.paidDate ? (': ' + (task.paidDate | date: 'shortDate')) : ''}}</span>
                <span *ngIf="!task.paymentStatus">&nbsp;|&nbsp;Not Paid</span>
            </div>
            <div class="hide-md p-col-12" style="padding: 0px; padding-bottom: .5em;"></div>
        </div>
    </div>
    <div class="p-col-12 p-md-4" style="text-align: right;">
        <button class="buttonStyle" [ngClass]="{'view-button': pdfReceiptCount > 0 || nonPdfReceiptCount > 0}" pButton type="button" label="View Work Details" (click)="navigate()" class="buttonStyle"></button>
        <button class="buttonStyle receiptBtn" [ngClass]="{'view-button': pdfReceiptCount > 0}" *ngIf="pdfReceiptCount > 0" pButton type="button"  [label]="pdfReceiptCount > 1 ? 'Download Cash Payment PDF Receipts' : 'Download Cash Payment PDF Receipt'" (click)="openPDFReceipts()"></button>
        <button class="buttonStyle receiptBtn" [ngClass]="{'view-button': nonPdfReceiptCount > 0}"*ngIf="nonPdfReceiptCount > 0" pButton type="button" [label]="nonPdfReceiptCount > 1 ? 'View Card Payment Receipts' : 'View Card Payment Receipt'" (click)="openNonPDFReceipts()"></button> <!--View Card/ACH Payment-->
    </div>
</div>