<div class="p-grid workStyle">
  <div class="p-col-12 p-md-8">
    <div class="p-grid">
      <div class="p-col-12 p-md-8 header">
        Quote [<span class="link" (click)="navigate()">{{quote.quoteNumber}}</span>] <span style="font-weight: normal;">{{quote.address.street}}, {{quote.address.city}}, {{quote.address.state.abbreviation}} {{quote.address.zip}}</span>
      </div>
      <div class="p-col-12 p-md-4" style="font-weight: bold;">
        Quote Created: {{quote.createdDate | date: 'shortDate'}} 
        <div *ngIf="current">
          Quote Expires: {{getExpireDate() | date: 'shortDate'}}
        </div>
        <div *ngIf="!current && quote.signedDate" style="color: green">
          Quote Signed: {{quote.signedDate | date: 'shortDate'}}
        </div>
        <div *ngIf="!current && quote.rejectedDate" style="color: red">
          Quote Rejected: {{quote.rejectedDate | date: 'shortDate'}}
        </div>
        <div *ngIf="!current && quote.completionStatus === completionStatus.Quote_Expired" style="color: red">
          Quote Expired: {{getExpireDate() | date: 'shortDate'}}
        </div>
      </div>
    </div>
    <div class="p-grid hide-sm" *ngFor="let task of tasks">
      <div class="p-col-5">
        {{task.name}}: {{getTreeList(task)}}, {{task.location}}
      </div>
      <div class="p-col-3" style="text-align: right;">
        <span *ngIf="(canSeeHiddenPrice || !quote.hidePrice) && task.fixedPrice">
          {{task.price | currency}}
        </span>
        <span *ngIf="(canSeeHiddenPrice || !quote.hidePrice) && !task.fixedPrice">
          <span *ngIf="task.hourlyRate">{{task.hourlyRate | currency}} per hour</span>
          <div class="fine-print" style="text-align: right; width: 100%">
            <span *ngIf="task.minPrice || task.maxPrice">Please note there will be a </span>
            <span *ngIf="task.minPrice">minimum charge of {{task.minPrice | currency}}</span>
            <span *ngIf="task.minPrice && task.maxPrice"> and a </span>
            <span *ngIf="task.maxPrice">maximum charge of {{task.maxPrice | currency}}</span>
          </div>
        </span>
      </div>
      <div class="p-col-4">
        {{getTaskStatusDisplay(task)}}
      </div>
    </div>
  </div>
  <div class="p-col-12 p-md-4" style="text-align: right;">
    <button pButton type="button" [label]="buttonText" (click)="navigate()" class="buttonStyle"></button>
  </div>
</div>